import React from 'react'
import Layout from '../components/layout/layout'
import PageHeader from '../components/pageheader/pageheader'
import Section from '../components/section/section'

import BSLogo from '../images/distributors/bs.png'
import HighlineLogo from '../images/distributors/highline.jpg'
import InternationalLogo from '../images/distributors/international.jpg'
import ServiceChampLogo from '../images/distributors/servicechamp.png'

const DistributorsPage = () => {
  return (
    <Layout>

      <PageHeader title="Distributors" />

      <Section color="white">
        <div className="container">
          <div className="text-center">
            <h3 className="section-subheading text-muted mb-5">NGEN's products are professional use only and can be purchased from one of our distributor partners listed below.</h3>
          </div>
          <div className="row align-items-end justify-content-center text-center">
            <div className="col-4 my-3">
              <img className="img-fluid" style={{width: '60%'}} src={BSLogo} alt="" />
              <h2 className="text-blue mt-4 mb-3" style={{fontSize: '1.7rem'}}>BS Products</h2>
              <div className="d-flex flex-wrap justify-content-center">
                <a href="https://bsquicklube.com/" target="_blank" rel="noreferrer" className="btn btn-primary m-1">Website</a> &nbsp;
                <a href="tel:+18012142550" className="btn btn-primary m-1"><nobr>(801) 214-2550</nobr></a>
              </div>
            </div>
            <div className="col-1"></div>
            <div className="col-4 my-3">
              <img className="img-fluid" style={{width: '90%'}} src={HighlineLogo} alt="" />
              <h2 className="text-blue mt-4 mb-3" style={{fontSize: '1.7rem'}}>Highline Warren</h2>
              <div className="d-flex flex-wrap justify-content-center">
                <a href="https://highlinewarren.com/" target="_blank" rel="noreferrer" className="btn btn-primary m-1">Website</a>
                <a href="tel:+18009862211" className="btn btn-primary m-1"><nobr>(800) 986-2211</nobr></a>
              </div>
            </div>
          </div>
          <div className="row align-items-end justify-content-center text-center">
            <div class="col-4 my-3">
              <img className="img-fluid" style={{width: '90%'}} src={InternationalLogo} alt="" />
              <h2 className="text-blue mt-4 mb-3" style={{fontSize: '1.7rem'}}>International Filters</h2>
              <div className="d-flex flex-wrap justify-content-center">
                <a href="https://internationalfilters.com/" target="_blank" rel="noreferrer" className="btn btn-primary m-1">Website</a>
                <a href="tel:+18008722333" className="btn btn-primary m-1"><nobr>(800) 872-2333</nobr></a>
              </div>
            </div>
            <div className="col-1"></div>
            <div class="col-4 my-3">
              <img className="img-fluid" style={{width: '70%'}} src={ServiceChampLogo} alt="" />
              <h2 className="text-blue mt-4 mb-3" style={{fontSize: '1.7rem'}}>Service Champ</h2>
              <div className="d-flex flex-wrap justify-content-center">
                <a href="https://www.servicechamp.com/" target="_blank" rel="noreferrer" className="btn btn-primary m-1">Website</a>
                <a href="tel:+18002210216" className="btn btn-primary m-1"><nobr>(800) 221-0216</nobr></a>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default DistributorsPage
